const english = {
    'USER.Button_Text_Create': 'Create user',
    'USER.Button_Text_Delete': 'Delete User',
    'GROUP.Button_Text_Create': 'Create Group',
    'GROUP.Button_Text_Delete': 'Delete Group',
    'SUBCONTRACTOR.Button_Text_Create': 'Create Subcontractor',
    'SUBCONTRACTOR.Button_Text_Delete': 'Delete Subcontractor',
    'SUBCONTRACTOR.Button_Text_Import': 'Import Subcontractors',
    'STAND.Button_Text_Create': 'Create Stand',
    'STAND.Button_Text_Delete': 'Delete Stand',
    'EXHIBITION.Button_Text_Create': 'Create Exhibition',
    'EXHIBITION.Button_Text_Delete': 'Delete Exhibition',
    'EXHIBITION.Button_Text_Import': 'Import Exhibition',
    'JOB.Button_Text_Create': 'Create Job',
    'JOB.Button_Text_Add': 'add job',
    'JOB.Type_Text_Claim': 'MP Claim',
    'JOB.Type_Text_CustomerClaim': 'Customer Claims',
    'JOB.Type_Text_LastMinute': 'Last Minute',
    'JOB.Type_Text_Damage': 'Damage',
    'JOB.Type_Text_ProtocolDefect': 'Protocol Defect',
    'JOB.Status_Text_Open': 'Open',
    'JOB.Status_Text_InProgress': 'In progress',
    'JOB.Status_Text_Completed': 'Completed',
    'HALL.Button_Text_Create': 'Create Hall',
    'HALL.Button_Text_Delete': 'Delete Hall',
    'FURNISHING.Button_Text_AddFurnishing': 'Add Furnishing',
    'FURNISHING.Button_Text_FurnishingImport': 'FURNISHING IMPORT',
    'CRAFT.Button_Text_AddCraft': 'Add Craft',
    'CRAFT.Button_Text_CreateCraft': 'Create Craft',
    'TABLES.Column_Title_Name': 'Name',
    'TABLES.Column_Title_Phone': 'Phone',
    'TABLES.Column_Title_Email': 'Email',
    'TABLES.Column_Title_Company': 'Company',
    'TABLES.Column_Title_UserGroup': 'User group',
    'TABLES.Column_Title_User': 'User',
    'TABLES.Column_Title_Exhibition': 'Exhibition',
    'TABLES.Column_Title_Time': 'Time',
    'TABLES.Column_Title_Hall': 'Hall',
    'TABLES.Column_Title_Halls': 'Halls',
    'TABLES.Column_Title_DamageFilesCount': 'Damage files',
    'TABLES.Column_Title_StandNumber': 'Stand №',
    'TABLES.Column_Title_Craft': 'Craft',
    'TABLES.Column_Title_Crafts': 'Crafts',
    'TABLES.Column_Title_JobNumber': 'Job №',
    'TABLES.Column_Title_Jobs': 'Jobs',
    'TABLES.Column_Title_JobTableNumber': 'number',
    'TABLES.Column_Title_CadNumber': 'Cad №',
    'TABLES.Column_Title_JobType': 'Job Type',
    'TABLES.Column_Title_Type': 'Type',
    'TABLES.Column_Title_Message': 'Message',
    'TABLES.Column_Title_Label': 'Label',
    'TABLES.Column_Title_GroupRights': 'Group Rights',
    'TABLES.Column_Title_NumberUsers': 'Number of users',
    'TABLES.Column_Title_NumberStands': 'Number of stands',
    'TABLES.Column_Title_Stands': 'Stands',
    'TABLES.Column_Title_Title': 'title',
    'TABLES.Column_Title_HallNumber': 'hall number',
    'TABLES.Column_Title_Number': 'number',
    'TABLES.Column_Title_Exhibitor': 'exhibitor',
    'TABLES.Column_Title_AcquiseNumber': 'acquisition',
    'TABLES.Column_Title_ProaufNumber': 'proauf',
    'TABLES.Column_Title_HallName': 'hall',
    'TABLES.Column_Title_Corridor': 'corridor',
    'TABLES.Column_Title_Block': 'block',
    'TABLES.Column_Title_DeliveryTime': 'deadline',
    'TABLES.Column_Title_Materials': 'materials',
    'TABLES.Column_Title_Status': 'status',
    'TABLES.Column_Title_Description': 'description',
    'TABLES.Column_Title_Created': 'created',
    'TABLES.Column_Title_InProgress': 'in progress',
    'TABLES.Column_Title_Completed': 'completed',
    'TABLES.Column_Title_Attachments': 'attachments',
    'TABLES.Column_Title_Acqusition': 'acqusition',
    'TABLES.Column_Title_HallPlan': 'hall plan',
    'TABLES.Column_Title_ConstractionStarts': 'Start of Build',
    'TABLES.Column_Title_ConstractionEnds': 'End of Build',
    'TABLES.Column_Title_EventStarts': 'Event starts',
    'TABLES.Column_Title_ElfeOnline': 'Exhibition is online',
    'TABLES.Column_Title_TimeCompletion': 'deadline',
    'TABLES.Column_Title_Subcontractor': 'subcontractor',
    'TABLES.Column_Title_ID': 'ID',
    'TABLES.Column_Sorting_Tooltip_Ascending': 'Click to sort ascending',
    'TABLES.Column_Sorting_Tooltip_Descending': 'Click to sort descending',
    'TABLES.Column_Sorting_Tooltip_Cancel': 'Click to cancel sorting',
    'TABLES.Column_Title_FileName': 'file name',
    'TABLES.Column_Title_FurnishingDescription': 'Description',
    'TABLES.Column_Title_Sku': 'SKU',
    'TABLES.Column_Title_Image': 'Photo',
    'TABLES.Column_Title_LastMinutePrice': 'Last minute price',
    'TABLES.Column_Title_Quantity': 'Quantity',
    'TABLES.Column_Title_ShelfNumber': 'Shelf No.',
    'TABLES.Column_Title_ShelfUser': 'User',
    'TABLES.Column_Title_ShelfDate': 'Date',
    'TABLES.Column_Title_GraphicMiss': 'Graphic status',
    'FORM_RULES.Min_NewPassword': 'Password must be at least { minLength } characters',
    'FORM_RULES.Required_NewPassword': 'Please input new Password',
    'FORM_RULES.Required_ConfirmPassword': 'Please confirm new password',
    'FORM_RULES.Required_ConfirmPasswordMatch': 'The entered passworts do not match.',
    'FORM_RULES.Required_Name': 'Please input name',
    'FORM_RULES.Required_Surname': 'Please input surname',
    'FORM_RULES.Required_Email': 'The input is not valid E-mail',
    'FORM_RULES.Required_Choose': 'Please Choose',
    'FORM_RULES.Required_Phone': 'Please input phone',
    'FORM_RULES.Required_GroupName': 'Please input group name',
    'FORM_RULES.Required_Title': 'Please input title',
    'FORM_RULES.Required_Number': 'Please input number',
    'FORM_RULES.Required_Where': 'Please choose where to change the time',
    'FORM_RULES.Required_DeliveryTime': 'Please Choose Delivery Time',
    'FORM_RULES.Required_Exhibitor': 'Please input Exhibitor Name',
    'FORM_RULES.Required_ProafNumber': 'Please input Proauf number',
    'FORM_RULES.Required_StandNumber': 'Please input stand number',
    'FORM_RULES.Required_CadNumber': 'Please input cad number',
    'FORM_RULES.Required_KeyName': 'Please input key name',
    'FORM_RULES.Required_KeyNumber': 'Please input key number',
    'FORM_RULES.Required_HallName': 'Please input hall name',
    'FORM_RULES.Required_HallNumber': 'Please input hall number',
    'FORM_RULES.Required_Password': 'Please input your Password!',
    'FORMS.Input_Label_FurnishingDescr': 'Description',
    'FORMS.Input_Label_Sku': 'SKU',
    'FORMS.Input_Label_LastMinutePrice': 'Last minute price',
    'FORMS.Input_Label_LastMinuteQuantity': 'Quantity',
    'FORMS.Input_Label_Password': 'Password',
    'FORMS.Input_Label_Login': 'login',
    'FORMS.Input_Label_NewPassword': 'New password',
    'FORMS.Input_Label_ConfirmPassword': 'Confirm new password',
    'FORMS.Input_Label_Language': 'Language',
    'FORMS.Input_Label_Craft': 'craft',
    'FORMS.Input_Label_CraftType': 'craft type',
    'FORMS.Input_Label_CraftTitle': 'craft title',
    'FORMS.Input_Label_TimeOfCompletion': 'Deadline',
    'FORMS.Input_Label_Name': 'Name',
    'FORMS.Input_Label_Surname': 'Surname',
    'FORMS.Input_Label_Phone': 'Phone (login)',
    'FORMS.Input_Label_Email': 'Email',
    'FORMS.Input_Label_UsersGroup': 'Users Group',
    'FORMS.Input_Label_GroupName': 'Group Name',
    'FORMS.Input_Label_GroupColor': 'Group Color',
    'FORMS.Input_Label_CrmRights': 'CRM Rights',
    'FORMS.Input_Label_Attachment': 'Attachment',
    'FORMS.Input_Label_HallPlan': 'Hall plan',
    'FORMS.Input_Label_AppRights': 'ELFE APP Rights',
    'FORMS.Input_Label_Subcontractor': 'Subcontractor',
    'FORMS.Input_Label_AssignedExhibitions': 'assigned exhibitions',
    'FORMS.Input_Label_Title': 'title',
    'FORMS.Input_Label_Number': 'number',
    'FORMS.Input_Label_Where': 'Where',
    'FORMS.Input_Label_Hall': 'Hall',
    'FORMS.Input_Label_NewDeliveryTime': 'New Deadline',
    'FORMS.Input_Label_Exhibitor': 'Exhibitor Name',
    'FORMS.Input_Label_AcqstnNumber': 'Acquisition Number',
    'FORMS.Input_Label_ProaufNumber': 'Proauf number',
    'FORMS.Input_Label_Proauf': 'Proauf',
    'FORMS.Input_Label_Block': 'block',
    'FORMS.Input_Label_Corridor': 'corridor',
    'FORMS.Input_Label_Stand': 'stand',
    'FORMS.Input_Label_StandNum': 'stand №',
    'FORMS.Input_Label_StandNumber': 'stand number',
    'FORMS.Input_Label_CadNumber': 'cad number',
    'FORMS.Input_Label_Time': 'time',
    'FORMS.Input_Label_DeliveryTime': 'Deadline',
    'FORMS.Input_Label_Acquisition': 'acquisition',
    'FORMS.Input_Label_KeyName': 'key name',
    'FORMS.Input_Label_Key': 'key',
    'FORMS.Input_Label_JobType': 'job type',
    'FORMS.Input_Label_JobStatus': 'job status',
    'FORMS.Input_Label_JobNum': 'job №',
    'FORMS.Input_Label_Exhibition': 'exhibition',
    'FORMS.Input_Label_ExhibitionName': 'exhibition name',
    'FORMS.Input_Label_ExhibitionStarts': 'exhibition starts',
    'FORMS.Input_Label_ExhibitionEnds': 'exhibition ends',
    'FORMS.Input_Label_ElfeOnline': 'Exhibition is online',
    'FORMS.Input_Label_ConstructionStarts': 'construction starts',
    'FORMS.Input_Label_ConstructionEnds': 'construction ends',
    'FORMS.Input_Label_ExhibitionLink': 'exhibition link',
    'FORMS.Input_Label_Description': 'description',
    'FORMS.Input_Label_ChooseType': 'Choose type',
    'FORMS.Input_Label_Status': 'Status',
    'FORMS.Input_Label_ChooseStatus': 'Choose status',
    'FORMS.Input_Label_HallName': 'hall name',
    'FORMS.Input_Label_HallNumber': 'hall number',
    'FORMS.Input_Label_HallToMoveStandsTo': 'hall to move stands to',
    'FORMS.Input_Label_NewFinishingTime': 'New deadline',
    'FORMS.Input_Label_Company': 'company',
    'FORMS.Title_ChangePassword': 'Change password',
    'FORMS.Title_SendPassword': 'Send password',
    'FORMS.Title_AddUsers': 'Add users',
    'FORMS.Title_CopyFromEvent': 'Copy from event',
    'MODAL.Button_Text_CopyFromEvent': 'Copy from event',
    'FORMS.Title_AddSubcontractor': 'Add subcontractor',
    'FORMS.Title_AssignSubcontractors': 'Assign subcontractors',
    'FORMS.Title_ChooseSubcontractor': 'Choose subcontractor',
    'FORMS.Title_ChooseType': 'Choose type',
    'FORMS.Title_AddExhibitions': 'Add exhibitions',
    'FORMS.Title_DeliveryTime': 'Change deadline',
    'FORMS.Title_CraftTime': 'Change craft deadline',
    'FORMS.Title_CraftDone': 'Craft done',
    'FORMS.Title_CraftInProcess': 'Craft in process',
    'FORMS.Title_CraftAdditional': 'Create additional craft',
    'FORMS.Title_CraftDelete': 'Delete craft',
    'FORMS.Title_CreateLastMinJob': 'Create last minute job',
    'FORMS.Title_DeleteHall': 'Delete hall',
    'FORMS.Title_EditJob': 'Edit job',
    'FORMS.Title_EditFurnishing': 'Edit Furnishing',
    'FORMS.Title_AddFurnishing': 'Add Furnishing',
    'FORMS.Title_EditCraft': 'Edit craft',
    'FORMS.Title_CreateCraft': 'Create craft',
    'FORMS.Title_Text_Filters': 'Filters',
    'FORMS.Title_Text_CreateJob': 'Create job',
    'FORMS.Title_AddFile': 'Add file',
    'FORMS.Title_EditFile': 'Edit file',
    'FORMS.Title_AddPrint': 'Add print',
    'FORMS.Select_OptionLabel_English': 'English',
    'FORMS.Select_OptionLabel_Deutsch': 'Deutsch',
    'FORMS.Select_OptionLabel_ChooseColor': 'Choose Color',
    'FORMS.Select_OptionLabel_SelectedStands': 'In the selected stands ({ length })',
    'FORMS.Select_OptionLabel_AllStandsHall': 'In all stands in the hall',
    'FORMS.Select_OptionLabel_AllStandsExhibition': 'In all stands in the exhibition',
    'FORMS.Select_OptionLabel_HallNotSelected': 'Hall not selected',
    'FORMS.Select_OptionLabel_Completed': 'Completed',
    'FORMS.Select_OptionLabel_InProgress': 'In Progress',
    'FORMS.Radio_Label_Company': 'Company',
    'FORMS.Radio_Label_Subcontractor': 'Subcontractor',
    'FORMS.Radio_Label_ChoosenCraft': 'Choosen Craft',
    'FORMS.Radio_Label_AllCrafts': 'All Crafts',
    'FORMS.Checkbox_Label_JobCompleted': 'job is completed',
    'FORMS.Checkbox_Label_CraftCompleted': 'craft is completed',
    'FORMS.Input_Placeholder_Email': 'Email',
    'FORMS.Input_Placeholder_Password': 'Password',
    'FORMS.Input_Placeholder_Choose': 'Please Choose',
    'FORMS.Input_Placeholder_GroupName': 'Please input group name',
    'FORMS.Input_Placeholder_ChooseColor': 'Choose Color',
    'FORMS.Input_Placeholder_Where': 'Please choose where to change the time',
    'FORMS.Input_Placeholder_ChooseHall': 'Choose a hall',
    'FORMS.Input_Placeholder_ChooseExhibition': 'Choose an exhibition',
    'FORMS.Input_Placeholder_ChooseStand': 'Choose a stand',
    'FORMS.Input_Placeholder_DeliveryTime': 'Choose deadline',
    'FORMS.Input_Placeholder_AcqstnNumber': 'Enter acquisition number',
    'FORMS.Input_Placeholder_ProaufNumber': 'Enter proauf number',
    'FORMS.Input_Placeholder_CorridorNumber': 'Corridor number',
    'FORMS.Input_Placeholder_BlockNumber': 'Block number',
    'FORMS.Input_Placeholder_StandNumber': 'Stand number',
    'FORMS.Input_Placeholder_CadNumber': 'Cad number',
    'FORMS.Input_Placeholder_ShortDescription': 'Enter short description',
    'FORMS.Input_Placeholder_ChooseStatus': 'Choose status',
    'FORMS.Input_Placeholder_ChooseType': 'Choose type',
    'FORMS.Input_Placeholder_ChooseCraftType': 'Choose craft type',
    'FORMS.Input_Placeholder_ExhibitionName': 'Please input exhibition name',
    'FORMS.Input_Placeholder_HallName': 'Please input hall name',
    'FORMS.Input_Placeholder_CraftCompletion': 'Please choose craft deadline',
    'FORMS.Input_Placeholder_SelectDate': 'Select date',
    'FORMS.Input_Placeholder_StartDate': 'Start date',
    'FORMS.Input_Placeholder_EndDate': 'End date',
    'FORMS.Input_Placeholder_Company': 'Company',
    'FORMS.Input_Placeholder_SelectStand': 'Select stand',
    'SEARCH.Input_Placeholder_User': 'Search for users',
    'SEARCH.Input_Placeholder_Table_User': 'Search for name, email, phone',
    'SEARCH.Input_Placeholder_Subcontractor': 'Search for subcontractor',
    'SEARCH.Input_Placeholder_Table_Subcontractor': 'Search for ID, title, number',
    'SEARCH.Input_Placeholder_Exhibition': 'Search for exhibitions',
    'SEARCH.Input_Placeholder_Stand': 'Search for stand',
    'SEARCH.Input_Placeholder_Table_Stand': 'Search stand',
    'SEARCH.Input_Placeholder_Table_SearchKeyWords': 'Search for key words',
    'SEARCH.Input_Placeholder_MultiSelect': 'Search',
    'ACTIONS.List_Item_Label_StandPlan': 'Stand drawing',
    'ACTIONS.List_Item_Label_Graphic': 'graphic',
    'ACTIONS.List_Item_Label_Photo': 'photo',
    'ACTIONS.List_Item_Label_Info': 'Info',
    'ACTIONS.List_Item_Label_HallPlan': 'hall plan',
    'ACTIONS.List_Item_Label_Image': 'photo',
    'ACTIONS.List_Item_Label_ShowQRCode': 'Show QR Code',
    'ACTIONS.List_Item_Label_PrintLabel': 'Print Label',
    'ACTIONS.List_Item_Label_Collected': 'Collected',
    'ACTIONS.List_Item_Label_Open': 'Open',
    'GLOBAL.Button_Text_Save': 'Save',
    'GLOBAL.Button_Text_Send': 'Send',
    'GLOBAL.Button_Text_SaveSend': 'Save and send',
    'GLOBAL.Button_Text_SaveChanges': 'Save changes',
    'GLOBAL.Button_Text_UserToGroup': 'Add user to group',
    'GLOBAL.Button_Text_Update': 'update',
    'GLOBAL.Button_Text_Reimport': 'reimport',
    'GLOBAL.Button_Text_Cancel': 'Cancel',
    'GLOBAL.Button_Text_Delete': 'Delete',
    'GLOBAL.Button_Text_Remove': 'Remove',
    'GLOBAL.Button_Text_Confirm': 'Confirm',
    'GLOBAL.Button_Text_AddFile': 'Add file',
    'GLOBAL.Button_Text_UploadFile': 'Upload file',
    'GLOBAL.Button_Text_UserGroup': 'User group',
    'GLOBAL.Button_Text_Login': 'Login',
    'GLOBAL.Button_Text_BackHome': 'Back Home',
    'GLOBAL.Modal_Text_UserSendPass': 'Are you sure you want to send a{ br }new password?',
    'GLOBAL.Modal_Text_RemoveText': 'Are you sure you want to remove the { deleteObjectType }?',
    'GLOBAL.Modal_Text_DeleteText': 'Are you sure you want to delete the { deleteObjectType }?',
    'GLOBAL.Modal_Text_RemoveFrom': 'from the',
    'GLOBAL.Modal_Text_DeleteHallStands': 'You want to delete a hall that contains stands. Please choose another hall to move stands to',
    'GLOBAL.Modal_Text_DeleteHall': 'Are you sure you want to delete the hall',
    'GLOBAL.Modal_Text_ResetFilters': 'Reset filters',
    'GLOBAL.Button_Text_ExportCsv': 'export',
    'GLOBAL.Button_Text_SubcontractorsFile': 'The subcontractors file is importing',
    'GLOBAL.Button_Text_DownloadReport': 'download report',
    'GLOBAL.Button_Text_Actions': 'Actions',
    'GLOBAL.Button_Text_ImportGraphicList': 'Import Graphic List',
    'GLOBAL.Link_Text_Download': 'Download',
    'GLOBAL.Link_Text_Unassigned': 'unassigned',
    'GLOBAL.Link_Text_Assigned': 'Assigned',
    'GLOBAL.Text_NoData': 'No Data',
    'GLOBAL.Text_Status_Disabled': 'Disabled',
    'GLOBAL.Text_Status_InProgress': 'In progress',
    'GLOBAL.Text_Status_Completed': 'Completed',
    'GLOBAL.Text_Status_Overdue': 'Overdue',
    'GLOBAL.Text_Status_ChangePassUser': 'Password sent by { user } at { dateTime }',
    'MODAL.Button_Text_Choose': 'Choose',
    'MODAL.Button_Text_AddExhibition': 'Add Exhibition',
    'MODAL.Button_Text_Stands': 'Stands',
    'MODAL.Button_Text_Crafts': 'Crafts',
    'MODAL.Button_Text_DeliveryTime': 'Change deadline',
    'MODAL.Button_Text_CraftTime': 'Change craft deadline',
    'MODAL.Button_Text_AssignSubcontractors': 'Assign subcontractors',
    'MODAL.Button_Text_CraftDone': 'Craft done',
    'MODAL.Button_Text_CraftInProcess': 'Craft in process',
    'MODAL.Button_Text_CraftAdditional': 'Create additional craft',
    'MODAL.Button_Text_CraftDelete': 'Delete craft',
    'MODAL.Button_Text_JobCreate': 'Create Job',
    'MODAL.Button_Text_AddUserEvent': 'Add user to event',
    'MODAL.Button_Text_AddPrint': 'Add print',
    'FORMS.Button_Text_ApplyFilters': 'Apply filters',
    'FORMS.Button_Text_ResetFilters': 'Reset filters',
    'FORMS.Button_Text_AddKey': 'Add new key',
    'ENTITY.Modal_Title_User': 'user',
    'ENTITY.Modal_Title_Group': 'group',
    'ENTITY.Modal_Title_ExhibitionStand': 'exhibition stand',
    'ENTITY.Modal_Title_ExhibitionEvent': 'exhibition event',
    'ENTITY.Modal_Title_Exhibition': 'exhibition',
    'ENTITY.Modal_Title_Subcontractor': 'subcontractor',
    'ENTITY.Modal_Title_StandKey': 'stand key',
    'ENTITY.Modal_Title_Stand': 'stand',
    'ENTITY.Modal_Title_Craft': 'craft',
    'ENTITY.Modal_Title_Job': 'job',
    'ENTITY.Modal_Title_Furnishing': 'Furnishing',
    'ENTITY.Modal_Title_Document': 'document',
    'ENTITY.Modal_Title_Downloading': 'Wait is downloading the file...',
    'ENTITY.Modal_Title_Importing': 'The event file is importing...',
    'ENTITY.Modal_Title_Imported': 'The event file has been imported:',
    'MENUS.Label_Exhibitions': 'exhibitions',
    'MENUS.Label_Users': 'users',
    'MENUS.Label_Subcontractors': 'subcontractors',
    'MENUS.Label_LastMin': 'last minute',
    'MENUS.Label_GraphicStorage': 'graphics storage',
    'MENUS.Label_GraphicStore': 'store graphics',
    'MENUS.Label_GraphicHandout': 'graphics hand out',
    'MENUS_DROP.Label_MyProfile': 'My user account',
    'MENUS_DROP.Label_Logout': 'Logout',
    'MENUS_DROP.Label_Edit': 'Edit',
    'MENUS_DROP.Label_ViewLogs': 'View logs',
    'MENUS_DROP.Label_ViewKeys': 'View keys',
    'MENUS_DROP.Label_SendPass': 'Send password',
    'MENUS_DROP.Label_Delete': 'Delete',
    'MENUS_DROP.Label_Mark': 'Mark',
    'MENUS_DROP.Label_InProgress': 'in progress',
    'MENUS_DROP.Label_Open': 'open',
    'MENUS_DROP.Label_Done': 'done',
    'MENUS_DROP.Label_Make': 'Make',
    'MENUS_DROP.Label_Online': 'online',
    'MENUS_DROP.Label_Offline': 'offline',
    'MENUS_DROP.Label_UploadHallPlan': 'Upload hall plan',
    'MENUS_DROP.Label_LastMinReport': 'Last Minute report',
    'MENUS_DROP.Label_LastMinOrders': 'Last Minutes orders',
    'MENUS_DROP.Label_ComplainsReport': 'MP Claims report',
    'MENUS_DROP.Label_ExhibitorReport': 'Exhibitor Claim',
    'MENUS_DROP.Label_DamageReport': 'Damage report',
    'MENUS_DROP.Label_DamageReportAll': 'Damage report all',
    'MENUS_DROP.Label_ElfeOnline': 'Make online in ELFE',
    'MENUS_DROP.Label_ElfeOffline': 'Make offline in ELFE',
    'MENUS_DROP.Label_StandsQrCode': 'Stand Qrcodes',
    'NAVBAR_PAGES.Label_Overview': 'overview',
    'NAVBAR_PAGES.Label_Logs': 'logs',
    'NAVBAR_PAGES.Label_Users': 'users',
    'NAVBAR_PAGES.Label_UserGroups': 'user groups',
    'NAVBAR_PAGES.Label_GroupUsers': 'group users',
    'NAVBAR_PAGES.Label_Crafts': 'crafts',
    'NAVBAR_PAGES.Label_Jobs': 'jobs',
    'NAVBAR_PAGES.Label_Keys': 'keys',
    'NAVBAR_PAGES.Label_Stands': 'stands',
    'NAVBAR_PAGES.Label_Halls': 'halls',
    'NAVBAR_PAGES.Label_Documents': 'documents',
    'NAVBAR_PAGES.Label_Furnishings': 'Furnishings',
    'NAVBAR_PAGES.Label_GraphicStorage': 'graphic storage',
    'BREADCRUMBS.Label_Logs': 'logs',
    'BREADCRUMBS.Label_User': 'user',
    'BREADCRUMBS.Label_Users': 'users',
    'BREADCRUMBS.Label_UserGroups': 'user groups',
    'BREADCRUMBS.Label_GroupUsers': 'group users',
    'BREADCRUMBS.Label_Crafts': 'crafts',
    'BREADCRUMBS.Label_Jobs': 'jobs',
    'BREADCRUMBS.Label_Keys': 'keys',
    'BREADCRUMBS.Label_Stands': 'stands',
    'BREADCRUMBS.Label_Halls': 'halls',
    'BREADCRUMBS.Label_Exhibitions': 'exhibitions',
    'BREADCRUMBS.Label_Furnishings': 'Furnishing',
    'BREADCRUMBS.Label_Subcontractors': 'subcontractors',
    'BREADCRUMBS.Label_LastMin': 'last minute',
    'BREADCRUMBS.Label_Create': 'create',
    'BREADCRUMBS.Label_Documents': 'documents',
    'PAGES.Title_Dashboard': 'Dashboard',
    'PAGES.Title_Users': 'Users',
    'PAGES.Title_CreateUser': 'Create User',
    'PAGES.Title_CreateGroupUser': 'Create User Group',
    'PAGES.Title_UserGroups': 'User Groups',
    'PAGES.Title_Subcontractors': 'Subcontractors',
    'PAGES.Title_SubcontractorCreate': 'Create Subcontractor',
    'PAGES.Title_CreateStand': 'Create Stand',
    'PAGES.Title_LastMinJobs': 'Last minute jobs',
    'PAGES.Title_CreateHall': 'Create Hall',
    'PAGES.Title_Exhibitions': 'Exhibitions',
    'PAGES.Title_CreateExhibitions': 'Create Exhibition',
    'PAGES.Text_AllExisting': 'ALL existing last minute jobs in the stand',
    'PAGES.Text_NoExisting': 'There is no existing last minute jobs in the stand',
    'PAGES.Text_404': 'Sorry, the page you visited does not exist.',
    'CRAFTS.label_Logistics': 'logistics { num }',
    'CRAFTS.label_Floor': 'floor { num }',
    'CRAFTS.label_Assembly': 'assembly { num }',
    'CRAFTS.label_Electrics': 'electrics { num }',
    'CRAFTS.label_Graphic': 'graphic { num }',
    'CRAFTS.label_Furniture': 'furniture { num }',
    'CRAFTS.label_Multimedia': 'multimedia { num }',
    'CRAFTS.label_Cleaning': 'cleaning { num }',
    'CRAFTS.label_Additional': 'additional { num }',
    'CRAFTS.label_MpControl': 'mp control',
    'CRAFTS.label_Protocol': 'protocol',
    'CRAFTS.label_Service': 'service { num }',
    'CRAFTS.label_CollectGraphics': 'collect graphics { num }',
    'DATE.Time_Zone': 'en-GB'
};

export default english;