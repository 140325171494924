import EventOverviewSubPage from "./event-overview-sub-page";
import EventHallsSubPage from "./event-halls-sub-page";
import EventStandsSubPage from "./event-stands-sub-page";
import EventUsersSubPage from "./event-users-sub-page";
import EventAttachmentsSubPage from "./event-attachments-sub-page";
import EventFurnishingsSubPage from "./event-furnishings-sub-page"
import EventGraphicStorageSubPage from "./event-graphic-storage-sub-page"

const SubPage = {
    OverView: EventOverviewSubPage,
    Halls: EventHallsSubPage,
    Stands: EventStandsSubPage,
    Users: EventUsersSubPage,
    Attachments: EventAttachmentsSubPage,
    Furnishings: EventFurnishingsSubPage,
    GraphicStorage: EventGraphicStorageSubPage
};

export default SubPage;
