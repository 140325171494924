import StandFields from "../fields";
import { Localize } from "components/service";


const standsGraphicColumns = [
    {
        title: <Localize>TABLES.Column_Title_Exhibitor</Localize>,
        dataIndex: 'exhibitor',
        columnIndex: 'EXHIBITOR',
        sorter: true,
        width: '14%',
    },
    {
        title: <Localize>TABLES.Column_Title_ProaufNumber</Localize>,
        dataIndex: 'proauf_number',
        columnIndex: 'PROAUF_NUMBER',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Hall</Localize>,
        dataIndex: 'hall_name',
        columnIndex: 'HALL_NAME',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Corridor</Localize>,
        dataIndex: 'corridor',
        columnIndex: 'CORRIDOR',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Block</Localize>,
        dataIndex: 'block',
        columnIndex: 'BLOCK',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_StandNumber</Localize>,
        dataIndex: 'stand_number',
        columnIndex: 'STAND_NUMBER',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_CadNumber</Localize>,
        dataIndex: 'cad_number',
        columnIndex: 'CAD_NUMBER',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_ShelfNumber</Localize>,
        dataIndex: 'shelf_number',
        columnIndex: 'SHELF_NUMBER',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_ShelfDate</Localize>,
        dataIndex: 'shelf_date_at',
        columnIndex: 'SHELF_DATE_AT',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_ShelfUser</Localize>,
        dataIndex: 'shelf_user',
        columnIndex: 'SHELF_USER_ID',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_GraphicMiss</Localize>,
        dataIndex: 'relCraftCollectGraphic',
        align: 'center',
    },
    {
        title: <Localize>TABLES.Column_Title_Materials</Localize>,
        dataIndex: 'materials',
        columnIndex: 'MATERIALS',
    },
    {
        // title: "action",
        dataIndex: 'action',
        columnIndex: 'ACTION',
        align: 'right',
    }
];


const standsData = (stands) => {


    if (!stands)
    {
        return [];
    }

    return stands.map(stand => {


        return {
            key: stand.id,
            exhibitor: <StandFields.Title eeID={ stand.ee_id } id={ stand.id } title={ stand.exhibitor } />,
            proauf_number: stand.proauf_number ?? <strong>&mdash;</strong>,
            hall_name: <StandFields.Link eeID={ stand.hall.ee_id } id={ stand.hall_id } title={ stand.hall_name } />,
            corridor: stand.corridor?.length > 0 ? stand.corridor : <strong>&mdash;</strong>,
            block: stand.block?.length > 0 ? stand.block : <strong>&mdash;</strong>,
            stand_number: stand.stand_number?.length > 0 ? stand.stand_number : <strong>&mdash;</strong>,
            cad_number: stand.cad_number?.length > 0 ? stand.cad_number : <strong>&mdash;</strong>,
            shelf_number: <StandFields.ShelfNumber id={ stand.id } value={ stand.shelf_number } craftData={ stand.relCraftCollectGraphic } standId={ stand.id } />,
            shelf_date_at: <StandFields.ShelfDate date={ stand.shelf_date_at } />,
            shelf_user: <StandFields.ShelfUser user={ stand.relShelfUser } />,
            relCraftCollectGraphic: <StandFields.GraphicCraft craftData={ stand.relCraftCollectGraphic } standId={ stand.id } />,
            materials: <StandFields.MaterialsGraphic stand={ stand } />,
            action: <StandFields.ActionGraphic craftData={ stand.relCraftCollectGraphic } stand={ stand } />
        };

    });
};


const StandGraphicTableHelper = {
    columns: standsGraphicColumns,
    data: standsData,
}

export default StandGraphicTableHelper;