import useMutationEventCreateUpdate from './event-use-mutation-create-update';
import useMutationEventDelete from "./event-use-mutation-delete";
import useMutationEventImportReimport from "./event-use-mutation-import-reimport";
import useMutationEventReportExel from "./event-use-mutation-report-exel";
import useMutationEventReportPdf from "./event-use-mutation-report-pdf";
import useMutationEventImportCollectionGraphic from "./event-use-mutation-import-collect-graphic";
import useMutationEventAttachmentCreateUpdate from "./event-attachment-use-mutation-create-update";
import useMutationEventAttachmentDelete from "./event-attachment-use-mutation-delete";
import useMutationEventAttachmentOrderSet from "./event-attachment-use-mutation-order-set";
import useMutationEventPdfStadsQrCode from "./event-use-mutation-stands-qrcode-pdf";


export const eventUseMutation = {
  createUpdate: useMutationEventCreateUpdate,
  delete: useMutationEventDelete,
  importReimport: useMutationEventImportReimport,
  importCollectionGraphic: useMutationEventImportCollectionGraphic,
  reportExel: useMutationEventReportExel,
  reportPdf: useMutationEventReportPdf,
  attachmentCreateUpdate: useMutationEventAttachmentCreateUpdate,
  attachmentDelete: useMutationEventAttachmentDelete,
  attachmentOrderSet: useMutationEventAttachmentOrderSet,
  pdfStadsQrCode: useMutationEventPdfStadsQrCode,
}