import StandSelectWhereFormItem from "./stand-select-where-form-item";
import StandRadioSelect from "./stands-radio-select";
import StandSelect from "./stand-select-form-item";


const StandFormItems = {
    SelectWere: StandSelectWhereFormItem,
    RadioSelect: StandRadioSelect,
    StandSelect: StandSelect,
};

export default StandFormItems;