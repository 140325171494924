import { useMutation } from "@apollo/client";
import { useHistory } from 'react-router-dom';
import { EVENT_IMPORT_COLLECT_GRAPHICS } from "graphql/mutation/event-gql";
import { GET_EVENTS, GET_EVENT } from "graphql/query/event-gql";
import Event from "components/events";

import { successNotification, errorNotification } from "components/request-result";


const useMutationEventImportCollectionGraphic = ({ variables, eventID }) => {

    const history = useHistory();

    const query = eventID ? GET_EVENT : GET_EVENTS;

    variables = eventID ? { id: eventID } : variables;

    const [ _setEventImportCollectionGraphic, { data, loading } ] = useMutation(EVENT_IMPORT_COLLECT_GRAPHICS,
        {
            update(cache, { data }) {

                const {
                    eeImportCollectGraphics: {
                        label,
                        message,
                    }
                } = data;

                eventID && history.push(`${ Event.Const.basePath }/${ eventID }`);

                successNotification({
                    title: label,
                    description: message
                })
            },


            onError(error) {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: query,
                    variables
                },
            ]
        }
    );


    return {
        _setEventImportCollectionGraphic,
        dataMutationEventImportCollectionGraphic: data,
        loadingMutationEventImportCollectionGraphic: loading,
    }
};

export default useMutationEventImportCollectionGraphic;

