import StandTableHelper from './stand-table-helper';
import StandLogsTableHelper from './stand-logs-table-helper';
import StandGraphicTableHelper from './stand-graphic-table-helper';


const StandHelpers = {
    TableMain: StandTableHelper,
    TableLogs: StandLogsTableHelper,
    TableGraphic: StandGraphicTableHelper,
}

export default StandHelpers;