import { gql } from '@apollo/client';


export const EVENT_CREATE = gql`
    mutation EeCreate($input: EeFieldsInput!) {
        eeCreate(input: $input) {
        label
        message
        exhibitionEvent {
            id
            title
            status
            path_model
            start_at
            end_at
            constructionStart_at
            constructionEnd_at
            standsCount
            hallsCount
          }
        }
    }
`;

export const EVENT_UPDATE = gql`
    mutation EeUpdate($input: EeFieldsInput!) {
        eeUpdate(input: $input) {
        label
        message
        exhibitionEvent {
            id
            title
            status
            path_model
            start_at
            end_at
            constructionStart_at
            constructionEnd_at
            standsCount
            hallsCount
          }
        }
    }
`;

export const EVENT_COPY_FURNICHING = gql`
    mutation EeCopyFurnishing($ee_id: ID!, $furnishing_id: [ID]! ) {
        eeCopyFurnishing(ee_id: $ee_id, furnishing_id: $furnishing_id) {
        label
        message
        }
    }
`;

export const EVENT_DELETE = gql`
    mutation EeDelete($id: ID!) {
        eeDelete(id: $id) {
        label
        message
       }
    }
`;

export const EVENT_REMOVE_USER = gql`
    mutation EeRemoveUser(
        $user_id: ID!
        $ee_id: ID!
    ) {
        eeRemoveUser(
            user_id: $user_id
            ee_id: $ee_id
        ){
            label
            message
        }
    }
`;

export const EVENT_SYNC_USERS = gql`
    mutation EeSyncToUsers(
        $user_id: [ID]!,
         $ee_id: ID!) {
        eeSyncToUsers(
            user_id: $user_id
            ee_id: $ee_id
             ) {
        label
        message
        }
    }
`;

export const EVENT_IMPORT_REIMPORT = gql`
    mutation EeImportOrReImport($file: Upload!, $ee_id: ID) {
        eeImportOrReImport(file: $file, ee_id: $ee_id) {
            label
            message
            sync_info
        }
    }
`;

export const EVENT_IMPORT_COLLECT_GRAPHICS = gql`
    mutation EeImportCollectGraphics(
        $ee_id: ID,
        $file: Upload!
        ) {
            eeImportCollectGraphics( ee_id: $ee_id, file: $file) {
            label
            message
        }
    }
`;

export const EVENT_REPORT_EXEL = gql`
    mutation EeReportExel($ee_id: ID!, $type: EeReportTypeEnum!) {
        eeReportExel(ee_id: $ee_id, type: $type) {
        label
        message
        file
        }
    }
`;

export const EVENT_REPORT_PDF = gql`
    mutation EeReportPdf($ee_id: ID!, $type: EeReportTypeEnum!) {
        eeReportPdf(ee_id: $ee_id, type: $type) {
        label
        message
        file
        }
    }
`;

export const EVENT_REPORT_PDF_DAMAGES = gql`
    mutation EeReportPdfDamages($ee_id: ID!) {
        eeReportPdfDamages(ee_id: $ee_id) {
        label
        message
        file
        }
    }
`;

export const EVENT_PDF_STANDS_QRCODE = gql`
    mutation EePdfStadsQrCode($ee_id: ID!) {
        eePdfStadsQrCode(ee_id: $ee_id) {
        label
        message
        file
        }
    }
`;

export const EVENT_ATTCHMENT_CREATE = gql`
    mutation AttachmentCreate(
        $ee_id: ID!
        $file: Upload!
        $title: String
        $description: String
    ) {
        attachmentCreate(
        ee_id: $ee_id
        file: $file
        title: $title
        description: $description
        ) {
            label
            message
            attachment {
                id
                path
                server_name
                original_name
                extension
                size
                ee_id
                user_id
                title
                description
                order
                created_at
                updated_at
            }
        }
    }
`;

export const EVENT_ATTCHMENT_UPDATE = gql`
    mutation AttachmentUpdate(
        $id: ID!
        $title: String
        $description: String
    ) {
        attachmentUpdate(
        id: $id
        title: $title
        description: $description
        ) {
            label
            message
            attachment {
                id
                path
                server_name
                original_name
                extension
                size
                ee_id
                user_id
                title
                description
                order
                created_at
                updated_at
            }
        }
    }
`;

export const EVENT_ATTCHMENT_ORDER_SET = gql`
    mutation AttachmentsOrderSet(
        $ee_id: ID!
        $ids: [ID!]!
    ) {
        attachmentsOrderSet(
            ee_id: $ee_id
            ids: $ids
        ) {
            label
            message
            attachments {
                id
            }
        }
    }
`;

export const EVENT_ATTCHMENT_DELETE = gql`
    mutation AttachmentDelete( $id: ID!) {
            attachmentDelete( id: $id ) {
            label
            message
        }
    }
`;