const standsBasePath = '/stands';

export const DEFAULT_FILTER_FIELDS = [
   'AQUISE_NUMBER',
   'PROAUF_NUMBER',
   'BLOCK',
   'CAD_NUMBER',
   'HALL_ID',
   'CORRIDOR',
   'STAND_NUMBER',
   'DELIVERY_TIME_AT'
];


const StandConst = {
   basePath: standsBasePath,
};

export default StandConst;









