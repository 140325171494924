import React from "react";
import { Button, message } from 'antd';
import { useQuery } from "@apollo/client";
import { GET_EVENT } from 'graphql/query/event-gql';
import { Elements, PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import EventRoute from 'components/routes/events/event-route';
import Events from 'components/events';
import { UploadFile, Localize } from "components/service";
import Icons from "components/icons";


const EventPage = ({ match, history }) => {

    useActiveMenuItem([ "exhibitions" ], [ "exhibitions" ]);

    const path = history.location.pathname.split('/').pop()
    const eventID = match ? parseInt(match.params.eeID) : null;

    const { data: { exhibitionEvent: event } = {}, loading } = useQuery(GET_EVENT, {
        skip: !eventID,
        variables: {
            id: eventID
        },
        fetchPolicy: 'cache-and-network',
    });

    const {
        _setEventImportReimport,
        loadingMutationEventImportReimport,
        dataMutationEventImportReimport
    } = Events.Hooks.importReimport({ eventID });


    const {
        _setEventImportCollectionGraphic,
        loadingMutationEventImportCollectionGraphic
    } = Events.Hooks.importCollectionGraphic({ eventID });

    const {
        _setEventReportExel,
        loadingMutationEventReportExel
    } = Events.Hooks.reportExel();

    const {
        _setEventReportPdf,
        loadingMutationEventReportPdf
    } = Events.Hooks.reportPdf();

    const {
        _setEventPdfStadsQrCode,
        loadingMutationEventPdfStadsQrCode
    } = Events.Hooks.pdfStadsQrCode();

    const loadingFile = loadingMutationEventReportExel || loadingMutationEventReportPdf || loadingMutationEventPdfStadsQrCode;

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: "overview"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Halls" }).props.children,
            path: "halls"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Stands" }).props.children,
            path: "stands"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Furnishings" }).props.children,
            path: "furnishings"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Documents" }).props.children,
            path: "documents"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Users" }).props.children,
            path: "users"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_GraphicStorage" }).props.children,
            path: "graphic-storage"
        }
    ];

    const headerRightBar = event && (
        <>
            { loadingMutationEventImportReimport &&
                <Elements.Importing
                    loading={ loadingMutationEventImportReimport }
                    modalTitle={ <Localize>ENTITY.Modal_Title_Importing</Localize> } />
            }


            { dataMutationEventImportReimport &&
                <Elements.Imported
                    data={ dataMutationEventImportReimport.eeImportOrReImport }
                    modalTitle={ <Localize>ENTITY.Modal_Title_Imported</Localize> } />
            }

            { loadingFile &&
                <Elements.Importing
                    cancelHide={ loadingFile }
                    loading={ loadingFile }
                    maskClosable={ !loadingFile }
                    extraClass="arrow-down"
                    modalTitle={ <Localize>ENTITY.Modal_Title_Downloading</Localize> } />
            }


            <UploadFile
                varSetMutation={ { ee_id: eventID } }
                setMutation={ _setEventImportCollectionGraphic }
                loading={ loadingMutationEventImportCollectionGraphic }
                accept=".xlsx, .xls, .xml"
                uploadBtn={
                    <Button className='ant-btn-secondary'><Icons.Import /> <Localize>GLOBAL.Button_Text_ImportGraphicList</Localize></Button>
                } />

            <UploadFile
                varSetMutation={ { ee_id: eventID } }
                setMutation={ _setEventImportReimport }
                loading={ loadingMutationEventImportReimport }
                uploadBtn={
                    <Button className='ant-btn-secondary'><Icons.Import /> <Localize>GLOBAL.Button_Text_Reimport</Localize></Button>
                } />

            <Button
                onClick={ () => message.warning("This feature is coming soon!") }
                className='ant-btn-secondary'><Icons.Update />
                <span><Localize>GLOBAL.Button_Text_Update</Localize></span>
            </Button>

            <Events.Actions.Fields.DownloadReport
                eventID={ eventID }
                setEventReportExel={ _setEventReportExel }
                setEventReportPdf={ _setEventReportPdf }
                setEventPdfStadsQrCode={ _setEventPdfStadsQrCode }
            />
        </>
    );


    return (
        <PageWrapDefault
            className="page-event"
            loading={ loading }
            title={ path === 'create' ? <Localize>PAGES.Title_CreateExhibitions</Localize> : !loading && event ? event.title : 'Page 404' }
            dataExist={ path === 'create' ? true : !loading && event }
            headerRightBar={ headerRightBar }
            pageNavbar={ event ? pageNavbar : false }
            staticPath={ `${ Events.Const.basePath }/${ eventID }` }
        >
            <EventRoute
                event={ event }
                history={ history } />

        </PageWrapDefault>
    );
};

export default EventPage;


