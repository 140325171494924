import React, { useState } from "react";
import { Waypoint } from 'react-waypoint';
import { Form, Skeleton, Checkbox, Tag } from 'antd';
import { useQuery } from "@apollo/client";
import { GET_STANDS_CURSOR } from "graphql/query/stand-gql";
import MultiSelect from 'components/layout/form-elements/multi-select';
import { useDebounce } from "components/use-hooks";
import { Loader } from "components/request-result";
import { Localize, cursorPagination } from 'components/service';
import './stand-select.scss';


const StandSelect = ({
    form,
    ee_id,
    initialValue,
    model = "standsCursor",
    name = 'stand_id',
    loadingQuery
}) => {
    const [ searchStand, setSearchStand ] = useState("");
    const standListInit = initialValue?.map((stand) => stand?.id.toString());

    let { data, loading: loadingQueryCursor, fetchMore } = useQuery(GET_STANDS_CURSOR, {
        variables: {
            ee_id: +ee_id,
            text: useDebounce(searchStand),
            first: 25,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const {
        fetchMoreAction,
        pageInfo,
        node
    } =
        cursorPagination({
            model,
            data,
            loading: loadingQueryCursor,
            fetchMore
        });

    const StandInfoLabel = ({ stand }) => (
        <div className="stand-info-label">
            <div className="stand-name">{ stand.exhibitor }</div>
            <div className="stand-details">
                <Tag color="blue">Stand №:&nbsp; <strong>{ stand.stand_number || '—' }</strong></Tag>
                <Tag color="cyan">block:&nbsp; <strong>{ stand.block || '—' }</strong></Tag>
                <Tag color="green">corridor:&nbsp; <strong>{ stand.corridor || '—' }</strong></Tag>
                <Tag color="orange">hall:&nbsp; <strong>{ stand.hall_name || '—' }</strong></Tag>
            </div>
        </div>
    );

    const nodes = node
        .filter(({ node }) => {

            return !node.crafts || !node.crafts.some(craft => craft.type === "collect_graphics");
        })
        .map(({ node }) => ({
            label: <StandInfoLabel stand={ node } />,
            value: node.id
        }));

    const initialValues = initialValue?.map(
        stand => ({
            label: <StandInfoLabel stand={ stand } />,
            value: stand.id
        })
    ) || [];

    const loading = loadingQueryCursor || loadingQuery;

    return (
        <Form.Item name={ name }>
            <MultiSelect
                setSearch={ setSearchStand }
                search={ searchStand }
                disableBtn={ true }
                placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectStand" }).props.children }
            >
                { loading ? <Skeleton active={ true } paragraph={ { rows: 6 } } /> :
                    <Checkbox.Group
                        className='group-checkbox stand-select-group'
                        options={ [ ...new Set([ ...nodes, ...initialValues ].map(item => JSON.stringify(item, (key, value) =>

                            typeof value === 'object' && React.isValidElement(value) ?
                                { _isReactElement: true, exhibitor: value.props.stand.exhibitor, id: value.props.stand.id } :
                                value
                        ))) ].map(item => {
                            const parsedItem = JSON.parse(item);

                            if (parsedItem.label && parsedItem.label._isReactElement)
                            {
                                const stand = node.find(n => n.node.id === parsedItem.label.id)?.node ||
                                    initialValue?.find(s => s.id === parsedItem.label.id);
                                if (stand)
                                {
                                    parsedItem.label = <StandInfoLabel stand={ stand || { exhibitor: parsedItem.label.exhibitor } } />;
                                }
                            }
                            return parsedItem;
                        }) }
                        defaultValue={ standListInit }
                        onChange={ value => form.setFieldsValue({ [ name ]: value }) }
                    />
                }

                { pageInfo?.hasNextPage &&
                    <>
                        { true && <Loader style={ { marginTop: '-30px' } } /> }
                        <Waypoint onEnter={ () => fetchMoreAction() } />
                    </>
                }
            </MultiSelect>
        </Form.Item>
    );
};

export default StandSelect;