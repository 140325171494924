import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import Icons from 'components/icons';
import Events from 'components/events';
import Craft from 'components/craft';
import Stands from 'components/stands';
import { Localize } from 'components/service';
import { helperFunc } from 'utils'


const StandCraftsField = ({ stand, crafts }) => {


    const { charSplitToUpper } = helperFunc;
    const { craftColor, getIconCraft } = Craft.Helpers.Functions;
    const assignSubcontractors = crafts.filter(craft => craft?.subcontractor_id).length

    const {
        _setCraftCreateUpdate,
    } = Craft.Hooks.createUpdate({ craftID: true });

    return (
        <>
            <ul className='crafts-list'>
                {
                    crafts?.map(craft =>
                        <li key={ +craft.id } onClick={ () => {
                            _setCraftCreateUpdate({
                                variables: {
                                    input: {
                                        id: craft?.id ? +craft?.id : undefined,
                                        stand_id: +craft?.stand_id,
                                        status: craft?.type === 'collect_graphics'
                                            ? (craft?.status === 'done' ? 'open' : 'done')
                                            : (craft?.status === 'disabled' ? 'in_progress' : 'disabled'),
                                    }
                                }
                            });
                        } }>
                            <Tooltip overlayClassName="craft-tooltip" destroyTooltipOnHide={ true } title={ craft?.title }>
                                <span
                                    className={ `craft ${ craftColor(craft?.status) }` }>

                                    <span className='count'>
                                        { craft?.type.match(/\d+/) }
                                    </span>

                                    { getIconCraft(charSplitToUpper(craft?.type, '_', false)) }
                                </span>
                            </Tooltip>
                        </li>
                    )
                }
            </ul>

            {
                crafts.length ?

                    <Link
                        to={ `${ Events.Const.basePath }/${ stand?.ee_id }${ Stands.Const.basePath }/${ stand?.id }${ Craft.Const.basePath }` }
                        className='craft-assigned'
                    >

                        <span><Localize>GLOBAL.Link_Text_Assigned</Localize> { `${ assignSubcontractors }/${ crafts.length }` }</span>
                        <Icons.Arrow className='arrow-right' />
                    </Link> : <strong>&mdash;</strong>
            }

        </>

    );
};

export default StandCraftsField;
