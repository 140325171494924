import React from 'react';
import Icon from '@ant-design/icons';


const CheckBoxSvg = (props) => (
	<svg
		height="40px"
		width="40px"
		id="_x32_"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 25.6 25.6"
		xmlSpace="preserve"
		{ ...props }
	>
		<g>
			<path
				className="st0"
				points="335.644,414.285 53.466,414.285 53.466,132.107 291.098,132.107 344.564,78.64 0,78.64 0,467.751  389.106,467.751 389.106,441.018 389.106,323.746 335.644,377.213  "
				d="M16.782 20.714L2.673 20.714L2.673 6.605L14.555 6.605L17.228 3.932L0 3.932L0 23.388L19.455 23.388L19.455 22.051L19.455 16.187L16.782 18.861Z"
			/>
			<path
				className="st0"
				points="158.903,163.312 103.914,218.311 193.434,307.822 248.423,362.82 303.412,307.822 512,99.247  457.002,44.249 248.431,252.823  "
				d="M7.945 8.166L5.196 10.916L9.672 15.391L12.421 18.141L15.171 15.391L25.6 4.962L22.85 2.212L12.422 12.641Z"
			/>
		</g>
	</svg>


);

const CheckBoxIcon = () => {
	return <Icon component={ CheckBoxSvg } />;
};


export default CheckBoxIcon;