import { gql } from "@apollo/client";

export const GET_STANDS_CURSOR = gql`
  query StandsCursor(
    $text: String
    $ee_id: ID
    $hall_id: ID
    $where: QueryStandsCursorWhereWhereConditions
    $whereCrafts: QueryStandsCursorWhereCraftsWhereHasConditions
    $orderBy: [QueryStandsCursorOrderByRelationOrderByClause!]
    $first: Int
    $after: String
  ) {
    standsCursor(
      text: $text
      ee_id: $ee_id
      hall_id: $hall_id
      where: $where
      whereCrafts: $whereCrafts
      orderBy: $orderBy
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          ee_id
          hall_id
          hall_name
          exhibitor
          aquise_number
          proauf_number
          block
          corridor
          stand_number
          cad_number
          delivery_time_at
          file_stand_plan
          file_info
          file_graphic
          file_photo
          file_animation
          damageFilesCount
          exhibitionEvent {
            id
            title
            status
          }
          hall {
            id
            title
            ee_id
          }
          keys {
            stand_id
            number
            title
            id
          }
          crafts {
            id
            title
            subcontractor_id
            stand_id
            type
            status
          }
          getQRCode
          shelf_number
          shelf_user_id
          shelf_date_at
          relShelfUser {
            id
            name
            surname
          }
          relCraftCollectGraphic {
            id
            title
            type
            status
          }
          created_at
          updated_at
        }
      }
    }
  }
`;

export const GET_STAND = gql`
  query Stand($id: ID!, $ee_id: ID, $hall_id: ID) {
    stand(id: $id, ee_id: $ee_id, hall_id: $hall_id) {
      id
      ee_id
      hall_id
      hall_name
      exhibitor
      aquise_number
      proauf_number
      block
      corridor
      stand_number
      cad_number
      delivery_time_at
      file_stand_plan
      file_info
      file_graphic
      file_photo
      file_animation
      exhibitionEvent {
        id
        title
        status
      }
      hall {
        id
        title
      }
      keys {
        id
        title
        number
        stand_id
      }
      crafts {
        id
        subcontractor_id
        stand_id
        type
        status
      }
      created_at
      updated_at
    }
  }
`;