import React from "react";
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import Craft from "components/craft";
import StandConst from '../stand-const';
import Event from 'components/events';
import Icons from 'components/icons';
import { EntityRemoveButton, Localize } from 'components/service';


const StandGraphicActionField = ({ craftData, stand }) => {


    const history = useHistory();

    const craftID = craftData?.id;

    const {
        _setMutationCraftDelete,
        loadingMutationCraftDelete,
    } = Craft.Hooks.delete({ craftID });


    const menu = (
        <Menu
            items={ [
                {
                    label: <Localize>MENUS_DROP.Label_Edit</Localize>,
                    key: '1',
                    icon: <Icons.Edit />,
                    onClick: () => history.push(`${ Event.Const.basePath }/${ stand.ee_id }${ StandConst.basePath }/${ stand.id }`)
                },
                {
                    key: '4',
                    icon:
                        <EntityRemoveButton
                            modalButton={
                                <span style={ { display: 'flex', alignItems: 'center', width: '100%' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                            }
                            nameEntity={ Localize({ children: "ENTITY.Modal_Title_Craft" }).props.children }
                            dataNameEntity={ craftData?.type }
                            loading={ loadingMutationCraftDelete }
                            deleteMutation={ _setMutationCraftDelete }
                            variables={ {
                                id: craftID,
                            } }
                        />,
                },
            ] }
        />
    );

    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            overlay={ menu }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default StandGraphicActionField;