import StandTitleField from "./stand-title-field";
import StandActionField from "./stand-action-field";
import StandGraphicActionField from "./stand-graphic-action-field";
import StandMaterialsField from "./stand-materials-field";
import StandMaterialsGraphicField from "./stand-materials-graphic-field";
import StandCraftsField from "./stand-crafts-field";
import StandLinkField from "./stand-link-field";
import StandUserInfoField from "./stand-user-info-field";
import StandMessage from "./stand-message-field";
import StandShelfNumberField from "./stand-shelf-number-field";
import StandShelfUserField from "./stand-shelf-user-field";
import StandShelfDateField from "./stand-shelf-date-field";
import StandGraphicCraftField from "./stand-graphic-craft-field";


const StandFields = {
    Title: StandTitleField,
    Action: StandActionField,
    ActionGraphic: StandGraphicActionField,
    Materials: StandMaterialsField,
    MaterialsGraphic: StandMaterialsGraphicField,
    Crafts: StandCraftsField,
    Link: StandLinkField,
    UserInfo: StandUserInfoField,
    Message: StandMessage,
    ShelfNumber: StandShelfNumberField,
    ShelfUser: StandShelfUserField,
    ShelfDate: StandShelfDateField,
    GraphicCraft: StandGraphicCraftField,
}

export default StandFields;