import React from "react";
import { useParams } from "react-router-dom";
import { Form, Input, DatePicker, Button } from "antd";
import moment from 'moment';
import Hall from 'components/hall';
import { Localize } from "components/service";
import { DEFAULT_FILTER_FIELDS } from '../../stand-const';

const { RangePicker } = DatePicker;

const StandFiltersForm = ({
    action,
    event,
    setCount,
    setFilters,
    setFilterList,
    filterList,
    gqlBuilderWhere,
    standsFilters,
    form,
    excludeFields = [],
    filterTable
}) => {
    const { eeID, hallID } = useParams();

    const initialDate = (date, fieldName) => {
        const val = date?.[ fieldName ]?.value;
        if (val) return [ moment(val[ 0 ]), moment(val[ 1 ]) ];
    }

    const initialValFormatted = (date, fieldName) => {
        const val = date?.[ fieldName ]?.value;
        if (val) return val.replace(/[^a-z0-9]/gi, '');
    }

    const onChangeSetFilter = (e, name, value) => {
        if (e)
        {
            name = e.target.name
            value = e.target.value
        }

        if (name === 'AQUISE_NUMBER' || name === 'PROAUF_NUMBER' || name === 'BLOCK' || name === 'CAD_NUMBER')
        {
            value.toString().length > 0 ?
                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "LIKE",
                        value: `${ value }%`
                    }
                }) : setFilterList({ ...filterList, [ name ]: {} });
        } else
        {
            value.toString().length > 0 ?
                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "EQ",
                        value: value
                    }
                }) : setFilterList({ ...filterList, [ name ]: {} });
        }
    }

    const renderField = (fieldName) => {
        if (excludeFields.includes(fieldName)) return null;

        switch (fieldName)
        {
            case 'AQUISE_NUMBER':
                return (
                    <Form.Item
                        key={ fieldName }
                        initialValue={ initialValFormatted(standsFilters, fieldName) }
                        name={ fieldName }
                        label={ <Localize>FORMS.Input_Label_Acquisition</Localize> }
                        onChange={ onChangeSetFilter }>
                        <Input name={ fieldName } placeholder={ Localize({ children: "FORMS.Input_Placeholder_AcqstnNumber" }).props.children } />
                    </Form.Item>
                );
            case 'PROAUF_NUMBER':
                return (
                    <Form.Item
                        key={ fieldName }
                        initialValue={ initialValFormatted(standsFilters, fieldName) }
                        name={ fieldName }
                        label={ <Localize>FORMS.Input_Label_Proauf</Localize> }
                        onChange={ onChangeSetFilter }>
                        <Input name={ fieldName } placeholder={ Localize({ children: "FORMS.Input_Placeholder_ProaufNumber" }).props.children } />
                    </Form.Item>
                );
            case 'HALL_ID':
                return !hallID && (
                    <Hall.Forms.Fields.Select
                        key={ fieldName }
                        initialValue={ initialValFormatted(standsFilters, fieldName) }
                        form={ form }
                        ee_id={ +eeID }
                        name={ fieldName }
                        onChangeSetFilter={ onChangeSetFilter }
                        rules={ false }
                    />
                );
            case 'CORRIDOR':
                return (
                    <Form.Item
                        key={ fieldName }
                        initialValue={ initialValFormatted(standsFilters, fieldName) }
                        name={ fieldName }
                        label={ <Localize>FORMS.Input_Label_Corridor</Localize> }
                        onChange={ onChangeSetFilter }>
                        <Input name={ fieldName } placeholder={ Localize({ children: "FORMS.Input_Placeholder_CorridorNumber" }).props.children } />
                    </Form.Item>
                );
            case 'BLOCK':
                return (
                    <Form.Item
                        key={ fieldName }
                        initialValue={ initialValFormatted(standsFilters, fieldName) }
                        name={ fieldName }
                        label={ <Localize>FORMS.Input_Label_Block</Localize> }
                        onChange={ onChangeSetFilter }>
                        <Input name={ fieldName } placeholder={ Localize({ children: "FORMS.Input_Placeholder_BlockNumber" }).props.children } />
                    </Form.Item>
                );
            case 'STAND_NUMBER':
                return (
                    <Form.Item
                        key={ fieldName }
                        initialValue={ initialValFormatted(standsFilters, fieldName) }
                        name={ fieldName }
                        label={ <Localize>FORMS.Input_Label_StandNumber</Localize> }
                        onChange={ onChangeSetFilter }>
                        <Input name={ fieldName } placeholder={ Localize({ children: "FORMS.Input_Placeholder_StandNumber" }).props.children } />
                    </Form.Item>
                );
            case 'CAD_NUMBER':
                return (
                    <Form.Item
                        key={ fieldName }
                        initialValue={ initialValFormatted(standsFilters, fieldName) }
                        name={ fieldName }
                        label={ <Localize>FORMS.Input_Label_CadNumber</Localize> }
                        onChange={ onChangeSetFilter }>
                        <Input name={ fieldName } placeholder={ Localize({ children: "FORMS.Input_Placeholder_CadNumber" }).props.children } />
                    </Form.Item>
                );
            case 'DELIVERY_TIME_AT':
                return (
                    <Form.Item
                        key={ fieldName }
                        initialValue={ initialDate(standsFilters, fieldName) }
                        name={ fieldName }
                        label={ <Localize>FORMS.Input_Label_DeliveryTime</Localize> }>
                        <RangePicker
                            separator="&ndash;"
                            placeholder={ [ Localize({ children: "FORMS.Input_Placeholder_StartDate" }).props.children, Localize({ children: "FORMS.Input_Placeholder_EndDate" }).props.children ] }
                            onChange={ (_, dateSrings) =>
                                dateSrings[ 0 ] !== '' ?
                                    setFilterList({
                                        ...filterList,
                                        DELIVERY_TIME_AT: {
                                            column: "DELIVERY_TIME_AT",
                                            operator: "BETWEEN",
                                            value: dateSrings
                                        }
                                    }) : setFilterList({
                                        ...filterList,
                                        DELIVERY_TIME_AT: {}
                                    }) } />
                    </Form.Item>
                );
            default:
                return null;
        }
    };

    return (
        <div className="ant-form ant-form-vertical filters-form">
            { DEFAULT_FILTER_FIELDS.map(fieldName => renderField(fieldName)) }

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(gqlBuilderWhere({ ...standsFilters, ...filterList }));
                        setCount({ ...standsFilters, ...filterList });
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem(`standsFilters-${ filterTable }_eeID-${ event?.id }`);
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    )
}

export default StandFiltersForm;