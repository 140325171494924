import React from 'react';
import Icon from '@ant-design/icons';


const PrinterSvg = (props) => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <path d="M38.8,15H9.2c-2.3,0-4.2,1.9-4.2,4.2v12.7c0,2.3,1.9,4.2,4.2,4.2h4.1v7.3c0,0.5,0.4,0.9,0.9,0.9h19.5c0.5,0,0.9-0.4,0.9-0.9
    v-7.3h4.1c2.3,0,4.2-1.9,4.2-4.2V19.2C43,16.9,41.1,15,38.8,15z M32.1,41.6H15.9v-9.5h16.3V41.6z M38.8,31.8h-4.2v-0.6
    c0-0.5-0.4-0.9-0.9-0.9H14.3c-0.5,0-0.9,0.4-0.9,0.9v0.6H9.2c-0.8,0-1.5-0.7-1.5-1.5v-9.4c0-0.8,0.7-1.5,1.5-1.5h29.5
    c0.8,0,1.5,0.7,1.5,1.5v9.4C40.3,31.1,39.6,31.8,38.8,31.8z"/>
        <rect x="19.9" y="34.6" width="8.3" height="4.3" />
        <path d="M12.1,4.5v7.3h23.7V4.5H12.1z M33.2,9.2H14.8V7.2h18.4V9.2z" />
        <circle cx="35.1" cy="22.7" r="1.9" />
    </svg>

);

const PrinterIcon = props => {
    return <Icon className={ props.className } component={ PrinterSvg } />;
};


export default PrinterIcon;