import CraftEditForm from "./craft-edit-form";
import CraftFinishTimeForm from "./craft-change-finish-time-form";
import CraftActionForm from "./craft-action-form";
import CraftAssignCreate from "./craft-assign-create-form";
import CraftAddPrintForm from "./craft-add-print-form";
import CraftFormItems from "./fields";



import './forms.scss';

const CraftForms = {
    Fields  : CraftFormItems,
    Edit: CraftEditForm,
    FinishTime: CraftFinishTimeForm,
    Action: CraftActionForm,
    AssignCreate: CraftAssignCreate,
    AddPrint: CraftAddPrintForm,
};

export default CraftForms;
