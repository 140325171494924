import React from "react";
import { timeFormat } from "utils/helper-functions";

const StandShelfDateField = ({ date }) => {
    if (!date) {
        return <strong>&mdash;</strong>;
    }

    return (
        <span>{timeFormat(date)}</span>
    );
};

export default StandShelfDateField;