import React, { useState } from "react";
import { Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { GET_STANDS_CURSOR } from "graphql/query/stand-gql";

import Stands from "components/stands";
import Events from "components/events";
import Craft from "components/craft";
import { Tables } from "components/layout";
import { ModalStandard, Localize } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";
import { gqlBuilderWhere } from 'utils';


const basePath = Events.Const.basePath;
const tableHelper = Stands.Helpers.TableGraphic;


const EventGraphicStorageSubPage = ({ event }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ basePath }`
        },
        {
            label: event?.title,
            path: `${ basePath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Stands" }).props.children,
            path: `${ basePath }/${ event?.id }${ Stands.Const.basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const routeUrl = `${ basePath }/${ event?.id }${ Stands.Const.basePath }`;

    const objOrderBy = [
        {
            relCrafts: {
                aggregate: 'MAX',
                column: 'CREATED_AT'
            }, order: 'DESC'
        }
    ];

    const filterTable = 'table-graphic-stands';

    const standsFilters = JSON.parse(localStorage.getItem(`standsFilters-${ filterTable }_eeID-${ event?.id }`));
    const standsSorting = JSON.parse(localStorage.getItem(`standsSorting-${ filterTable }_eeID-${ event?.id }`));
    const standsSearch = JSON.parse(localStorage.getItem(`standsSearch-${ filterTable }_eeID-${ event?.id }`));

    const [ searchText, setSearchText ] = useState(standsSearch);
    const [ filters, setFilters ] = useState(gqlBuilderWhere(standsFilters));
    const [ resetSorting, setResetSorting ] = useState(false);


    return (
        <Tables.Cursor
            extraClass="table-graphic-stands"
            tableLayout=""
            model="standsCursor"
            query={ GET_STANDS_CURSOR }
            varSet={ {
                perPage: 30,
                ee_id: +event?.id,
                whereCrafts: {
                    column: 'TYPE',
                    value: "collect_graphics"
                }
            } }
            routeUrl={ routeUrl }
            searchText={ searchText }
            objFilter={ filters }
            objOrderBy={ standsSorting || objOrderBy }
            sortingName={ `standsSorting-${ filterTable }_eeID-${ event?.id }` }
            tableHelper={ tableHelper }
            resetSorting={ resetSorting }
        >
            <div className="table-action-bar">

                <div className="col">
                    <Stands.Filters.Table
                        event={ event }
                        setSearchText={ setSearchText }
                        setFilters={ setFilters }
                        filters={ filters }
                        standsFilters={ standsFilters }
                        searchText={ searchText }
                        setResetSorting={ setResetSorting }
                        objOrderBy={ objOrderBy }
                        excludeFields={ [ 'AQUISE_NUMBER', 'CAD_NUMBER' ] }
                        filterTable={ filterTable }
                    />
                </div>

                <div className="col">
                    <ModalStandard
                        width={ 520 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button
                                type="primary">
                                <PlusOutlined /> <Localize>MODAL.Button_Text_AddPrint</Localize>
                            </Button>
                        }>
                        <strong className="modal-title"><Localize>FORMS.Title_AddPrint</Localize></strong>
                        <Craft.Forms.AddPrint
                            propsObj={ {
                                query: GET_STANDS_CURSOR,
                                event: event
                            } } />
                    </ModalStandard>
                </div>
            </div>
        </Tables.Cursor>
    );
};

export default EventGraphicStorageSubPage;