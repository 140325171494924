import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { standUseMutation } from '../hooks';
import { UploadFile, Localize } from 'components/service';
import { useMutation } from '@apollo/client';
import { STAND_PRINT_COLLECT_GRAPHICS } from 'graphql/mutation/stand-gql';
import Icons from 'components/icons';
import { errorNotification } from "components/request-result";
// import { saveAsFile } from 'utils';


const StandMaterialsGraphicField = ({ stand }) => {
    const [ isLoading, setIsLoading ] = useState(false);

    const [ printCollectGraphics ] = useMutation(STAND_PRINT_COLLECT_GRAPHICS, {
        onCompleted: (data) => {
            if (data?.standPrintCollectGraphics?.file)
            {
                // saveAsFile.document(data.standPrintCollectGraphics.file, `collect-graphics-${ stand?.id }.pdf`);
                window.open(data.standPrintCollectGraphics.file, '_blank');
            }
            setIsLoading(false);
        },
        onError: (error) => {
            errorNotification(error);
            setIsLoading(false);
        }
    });

    const fileListItem = [
        {
            field: 'file_stand_plan',
            label: <Localize>ACTIONS.List_Item_Label_StandPlan</Localize>,
        },
        {
            field: 'file_graphic',
            label: <Localize>ACTIONS.List_Item_Label_Graphic</Localize>,
        },
        {
            field: 'file_photo',
            label: <Localize>ACTIONS.List_Item_Label_Photo</Localize>,
        } ]
        .map(({ field, label }) => {

            const {
                _setStandUploadFile,
                loadingMutationStandUploadFile,
            } = standUseMutation.UploadFile({ eeID: stand?.ee_id, standID: stand?.id });

            const fileName = stand?.[ field ]?.split('/')?.pop()?.split('~')?.pop();

            return (

                <li key={ field }>

                    { stand?.[ field ] ?

                        <div className='download-file'>
                            <Tooltip overlayClassName="craft-tooltip" destroyTooltipOnHide={ true } title={ fileName }>
                                <Link
                                    to='#'
                                    onClick={ () => {
                                        window.open(
                                            stand?.[ field ],
                                            '_blank'
                                        );
                                    } }

                                >
                                    <Icons.Download /> <span className='link-text'>{ label }</span>
                                </Link>
                            </Tooltip>
                            <span onClick={ () => {
                                _setStandUploadFile({
                                    variables: {
                                        input: {
                                            model: 'Stand',
                                            model_id: stand?.id,
                                            model_field: field,
                                            file: null
                                        }
                                    }
                                })
                            } }>

                                { loadingMutationStandUploadFile ? <Loading3QuartersOutlined spin /> : <Icons.Delete /> }
                            </span>

                        </div>
                        :
                        <UploadFile
                            setMutation={ _setStandUploadFile }
                            loading={ loadingMutationStandUploadFile }
                            accept="*/*"
                            varSetMutation={ {
                                input: {
                                    model: 'Stand',
                                    model_id: stand?.id,
                                    model_field: field,
                                }
                            } }
                            uploadBtn={
                                <div className='upload-file'>
                                    <span className='link-text'>{ label }</span>
                                    { loadingMutationStandUploadFile ? <Loading3QuartersOutlined spin /> : <Icons.Import /> }
                                </div>
                            } />
                    }

                </li>
            )
        });

    const qrCodeItem = stand?.getQRCode ? (
        <li key="qr-code">
            <div className='download-file'>
                <Link
                    to='#'
                    onClick={ () => {
                        window.open(stand.getQRCode, '_blank');
                    } }
                >
                    <Icons.QrCode /> <span className='link-text'><Localize>ACTIONS.List_Item_Label_ShowQRCode</Localize></span>
                </Link>
            </div>
        </li>
    ) : null;

    const printLabelItem = (
        <li key="print-label">
            <div className='download-file'>
                <Link
                    to='#'
                    onClick={ () => {
                        setIsLoading(true);
                        printCollectGraphics({
                            variables: {
                                stand_id: stand?.id
                            }
                        });
                    } }
                >
                    <Icons.Printer /> <span className='link-text'><Localize>ACTIONS.List_Item_Label_PrintLabel</Localize></span>
                    { isLoading && <Loading3QuartersOutlined spin style={ { marginLeft: '5px' } } /> }
                </Link>
            </div>
        </li>
    );

    return (
        <ul className='download-file-list'>
            { fileListItem }
            { qrCodeItem }
            { printLabelItem }
        </ul>
    );
};

export default StandMaterialsGraphicField;