import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/events/sub-pages';


const EventRoute = ({ event, history }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }/halls` }>
                <SubPage.Halls event={ event } />
            </Route>
            <Route path={ `${ path }/halls/page/:pageNum` } exact>
                <SubPage.Halls event={ event } />
            </Route>
            <Route path={ `${ path }/stands` } >
                <SubPage.Stands
                    event={ event } />
            </Route>
            <Route path={ `${ path }/stands/page/:pageNum` } exact>
                <SubPage.Stands
                    event={ event } />
            </Route>
            <Route path={ `${ path }/documents` } >
                <SubPage.Attachments
                    event={ event } />
            </Route>
            <Route path={ `${ path }/documents/page/:pageNum` } exact>
                <SubPage.Attachments
                    event={ event } />
            </Route>
            <Route path={ `${ path }/furnishings` } >
                <SubPage.Furnishings
                    event={ event } />
            </Route>
            <Route path={ `${ path }/furnishings/page/:pageNum` } exact>
                <SubPage.Furnishings
                    event={ event } />
            </Route>
            <Route path={ `${ path }/users` } >
                <SubPage.Users
                    event={ event } />
            </Route>
            <Route path={ `${ path }/users/page/:pageNum` } exact>
                <SubPage.Users
                    event={ event } />
            </Route>
            <Route path={ `${ path }/graphic-storage` } >
                <SubPage.GraphicStorage
                    event={ event } />
            </Route>
            <Route path={ `${ path }` }>
                <SubPage.OverView
                    event={ event }
                    history={ history } />
            </Route>
        </Switch>
    );
};

export default EventRoute;
