import React, { useState, useEffect } from "react";
import { Input, Button, Form } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "utils";
import StandForms from '../forms';
import Icons from 'components/icons';
import { DrawerStandart, Localize } from "components/service";
import { DEFAULT_FILTER_FIELDS } from '../stand-const';

const { Search } = Input;

const StandTableFilter = ({
    event,
    setSearchText,
    searchText,
    setFilters = false,
    filters,
    standsFilters,
    setResetSorting = () => { },
    objOrderBy,
    excludeFields = [],
    filterTable='table-stands'
}) => {
    const [form] = Form.useForm();
    const [filterList, setFilterList] = useState({});
    const [count, setCount] = useState();

    const filterCount = helperFunc.countFilterList(count);

    /*eslint-disable */
    useEffect(() => {
        if (filters) {
            localStorage.setItem(`standsFilters-${filterTable}_eeID-${event?.id}`, JSON.stringify({ ...standsFilters, ...filterList }));
        } else {
            const fieldsToReset = DEFAULT_FILTER_FIELDS.filter(field => !excludeFields.includes(field));
            form.resetFields(fieldsToReset);
        }

        if (searchText) {
            localStorage.setItem(`standsSearch-${filterTable}_eeID-${event?.id}`, JSON.stringify(searchText));
        } else {
            localStorage.removeItem(`standsSearch-${filterTable}_eeID-${event?.id}`);
            form.resetFields(['search']);
        }
    }, [filters, searchText]);

    useEffect(() => {
        if (standsFilters) {
            setCount(standsFilters);
        }
    }, []);
    /*eslint-enable */

    return (
        <Form
            key="filters-form"
            className="clear-css-prop table-filter-form"
            layout="vertical"
            form={form}>

            <Form.Item
                initialValue={searchText}
                name="search">

                <Search
                    className="filter-search"
                    prefix={<SearchOutlined />}
                    placeholder={Localize({ children: "SEARCH.Input_Placeholder_Table_Stand" }).props.children}
                    onChange={(e) => setSearchText(e.target.value)}
                />

            </Form.Item>

            <DrawerStandart
                width="287"
                extraClass={'modal-form filters'}
                modalButton={
                    <Button>
                        <Icons.Filter />
                        <span><Localize>FORMS.Button_Text_ApplyFilters</Localize></span>
                        {filterCount && <span className="filter-count">{filterCount}</span>}
                    </Button>
                }
            >
                <strong className="modal-title">
                    <Localize>FORMS.Title_Text_Filters</Localize>
                </strong>

                <StandForms.Filter
                    event={event}
                    setCount={setCount}
                    setFilters={setFilters}
                    setFilterList={setFilterList}
                    filterList={filterList}
                    gqlBuilderWhere={gqlBuilderWhere}
                    standsFilters={standsFilters}
                    form={form}
                    excludeFields={ excludeFields }
                    filterTable={filterTable}
                />

            </DrawerStandart>

            <Button
                type="link"
                onClick={() => {
                    setFilterList({});
                    setCount({});
                    setFilters(gqlBuilderWhere({}));
                    setSearchText('');
                    setResetSorting(objOrderBy);
                    localStorage.removeItem(`standsFilters-${filterTable}_eeID-${event?.id}`);
                    localStorage.removeItem(`standsSorting-${filterTable}_eeID-${event?.id}`);
                    localStorage.removeItem(`standsSearch-${filterTable}_eeID-${event?.id}`);
                }}
            >
                <span><Localize>FORMS.Button_Text_ResetFilters</Localize></span>
            </Button>
        </Form>
    );
};

export default StandTableFilter;
