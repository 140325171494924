import React from "react";
import { Link } from 'react-router-dom';
import User from 'components/users';

const StandShelfUserField = ({ user }) => {
    if (!user) {
        return <strong>&mdash;</strong>;
    }

    const { id, name, surname } = user;
    const userFullName = `${name} ${surname}`;

    return (
        <Link
            to={ {
                pathname: `${User.Const.basePath}/${id}`,
                state: {
                    breadcrumbSlug: {
                        id,
                        label: userFullName
                    }
                }
            } }
        >
            {userFullName}
        </Link>
    );
};

export default StandShelfUserField;